import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import '../app/fragments';
import '../app/styles.scss';
import SEO from '../components/seo';

const POST_TRANSITION_DELAY = 7_000;
const POST_FETCH_DELAY = 30_000; // 30 seconds
let currentMemory = 0;

const query = graphql`
  {
    allFile(sort: { fields: name, order: ASC }, filter: { relativeDirectory: { eq: "memories" } }) {
      nodes {
        name
        id
        publicURL
      }
    }
  }
`;


export default function LivePage({}) {
  const memories = useStaticQuery(query).allFile.nodes;

  return (
    <section data-theme="green">
      <SEO title="Live" />
      <div className="wedding-party">
        {memories.map(memory => (
          <div>
            {memory.name.replace('image','')}
            <img src={memory.publicURL} />
          </div>
        ))}
      </div>
    </section>
  );
}
